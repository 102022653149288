'use strict';

//スクロール途中からヘッダーを出現させるための設定を関数でまとめる
function FixedAnime() {
    var elemTop = 100;
    var scroll = $(window).scrollTop();
    if (window.matchMedia('(max-width: 1024px)').matches) {
        // sp
        if (scroll >= elemTop){
            $('#header .p-header-logo').addClass('fixed');
            $('#header .p-header-bottom').addClass('fixed');
        }else{
            $('#header .p-header-logo').removeClass('fixed');
            $('#header .p-header-bottom').removeClass('fixed');
            $('.p-gnav').css('display','none');
            $('.c-menu-trigger').removeClass('active');
        }
    }else{
        if (scroll >= elemTop){
            $('#header').addClass('fixed');
        }else{
            $('#header').removeClass('fixed');
        }
    }
}
  
// 画面をスクロールをしたら動かしたい場合の記述
$(window).on('scroll',function () {
    FixedAnime();/* スクロール途中からヘッダーを出現させる関数を呼ぶ*/
});
  
// ページが読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
    FixedAnime();/* スクロール途中からヘッダーを出現させる関数を呼ぶ*/
});

// アニメーション

$(window).on('load', function(){
    $('.loader-slide').addClass('open');
});



/*--------------------------------------------------------------------------*
* PAGE TOP
*--------------------------------------------------------------------------*/
$(function(){
    // #で始まるアンカーをクリックした場合に処理
    $('a[href^="#"]').on('click',function(){
      // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
      var adjust = -70;
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href= $(this).attr('href');
      // 移動先を取得
      var target = $(href == '#' || href == '' ? 'html' : href);
      // 移動先を調整
      var position = target.offset().top + adjust;
      // スムーススクロール
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
});